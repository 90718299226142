import { useState, useEffect} from "react";

import './App.css';
import MovieCard from "./MovieCard";
import SearchIcon from './search.svg';
// api key omdb d09a6a3e
const API_URL = 'https://www.omdbapi.com?apikey=d09a6a3e';

const App = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [movies, setMovies] = useState([]);
    const [movieCount, setMovieCount] = useState(0);
    const searchMovies = async(title) => {
        const response = await fetch(`${API_URL}&s=${title}`);
        const data = await response.json();

        // console.log(data);
        if( data.Response === 'False' )
        {
            setMovieCount(0);
            
        }
        else{
            setMovies(data.Search);
            setMovieCount(data.Search.length);  
        }
    }

    useEffect(
        () => {
            searchMovies('abc');
        }, []
    );

    return (
        <div className="app">
            <h1>MovieLand</h1>

            <div className="search">
                <input
                    placeholder="Search for movie"
                    value={searchTerm}
                    onChange={ (e) => setSearchTerm(e.target.value) }
                />
                <img 
                    src={SearchIcon}
                    alt="Search"
                    onClick={ () => searchMovies(searchTerm) }
                />
            </div>

            {
                movieCount > 0
                ?(
                    <div className="container">
                        {movies.map((movie) => (
                            <MovieCard movie={movie} key={movie.imdbID}/>
                        ))}
                        
                    </div>
                )
                :(
                    <div className="empty">
                        <h2>No Moives found.</h2>
                    </div>
                )
            }

            
        </div>
    );
}

export default App;